import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { getMappable } from '_utils/get-mappable'
import { IMG_AS_HERO_CONTAINER_STYLES, IMG_AS_HERO_IMG_STYLES } from '_utils/config'
import { useWindowSize } from '_hooks'
import { AppContext } from '_context'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { Container, Image, Text, ScrollArrow, Heading } from '_atoms'
import { Quote, ProudCases } from '_molecules'
import CamioLogo from '_images/svgs/camio-logo.inline.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const CamioCaseStudy = ({ data }) => {
  const content = data.contentfulCamioCaseStudy

  const [width] = useWindowSize()
  const isMobile = width <= 768

  const { setNavbarColor, setNavbarLight, setCustomBrandColor } = useContext(AppContext)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor('transparent')
    setNavbarLight(true)
    setCustomBrandColor('dark')
  }, [setNavbarColor, setNavbarLight, setCustomBrandColor])

  return (
    <Layout navbarColorOverride="transparent">
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <section className={styles.hero}>
        <Img
          fluid={isMobile ? content.heroImageMobile.fluid : content.heroImageDesktop.fluid}
          alt={
            isMobile ? content.heroImageMobile.description : content.heroImageDesktop.description
          }
          style={IMG_AS_HERO_CONTAINER_STYLES}
          imgStyle={IMG_AS_HERO_IMG_STYLES}
        />
        <Container>
          <Fade distance="20" bottom>
            <CamioLogo className={styles.camioLogo} />
          </Fade>
          <Fade distance="20" delay={200} bottom>
            <Text size="32" className={styles.heroDescription} splitParagraphs>
              {content.heroDescription.internal.content}
            </Text>
          </Fade>

          <Fade distance="20" delay={350} bottom>
            <ScrollArrow className={styles.heroArrowButton} isButton scrollId="challenge" />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(true)
            setCustomBrandColor('dark')
          }}
        />
      </section>

      <section className={styles.challenge} id="challenge">
        <Container>
          <Row className={styles.challengeRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeTextsWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h1"
                  bold
                  color="camioPrimary"
                  className={classNames(styles.heading, styles.challengeHeader)}
                >
                  {content.challengeTitle}
                  <Text color="camioSecondary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  size="22"
                  className={classNames(styles.description, styles.challengeDescription)}
                  color="dark"
                  splitParagraphs
                >
                  {content.challengeDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Img
                  fluid={content.challengeImage.fluid}
                  alt={content.challengeImage.description}
                  className={styles.challengeImageWrapperImage}
                  imgStyle={{ objectFit: 'contain', maxHeight: '80vh' }}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
            setCustomBrandColor(null)
          }}
        />
      </section>

      <section className={styles.quote1}>
        <Container className={styles.quote1ContentWrapper}>
          <Fade distance="10%" delay={200} bottom>
            <Quote
              text={content.quote1.text.internal.content}
              user={content.quote1.user}
              userPhoto={content.quote1.userImage.fixed}
              userJob={content.quote1.jobDescription}
              white
              color="vppBluePrimary"
              mobileComplete
            />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['camio-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.features}>
        <Container>
          {getMappable('features', 3, content).map((feature, index) => (
            <Row className={styles.featuresRow} key={feature.title}>
              <Col xs={12} sm={12} md={6} lg={6} className={styles.featuresRowTexts}>
                <Fade distance="10%" delay={(index + 1) * 200} bottom>
                  <Heading type="h4" bold color="camioPrimary" className={styles.heading}>
                    {feature.title}
                    <Text color="camioSecondary" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>
                <Fade distance="10%" delay={(index + 1) * 300} bottom>
                  <Text
                    className={classNames(styles.description, styles.featuresRowDescription)}
                    color="dark"
                    splitParagraphs
                    size="22"
                  >
                    {feature.description.internal.content}
                  </Text>
                </Fade>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className={styles.featuresRowImageWrapper}>
                <Fade distance="10%" delay={(index + 1) * 400} bottom>
                  <Image src={feature.image.file.url} alt={feature.image.description} />
                </Fade>
              </Col>
            </Row>
          ))}
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.smart}>
        <Container>
          <Row className={styles.smartRow}>
            <Col xs={12} sm={12} md={5} lg={5}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  bold
                  color="white"
                  className={classNames(styles.heading, styles.smartTitle)}
                >
                  {content.smartTitle}
                  <Text color="camioPrimary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={200} bottom>
                <Text
                  className={classNames(styles.description, styles.smartDescription)}
                  color="white"
                  splitParagraphs
                  size="22"
                >
                  {content.smartDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={7} lg={7}>
              <Fade distance="10%" delay={350} bottom>
                <Img
                  fluid={content.smartImage.fluid}
                  alt={content.smartImage.description}
                  className={styles.smartImage}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['camio-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.quote2}>
        <Container className={styles.quote2ContentWrapper}>
          <Fade distance="10%" delay={200} bottom>
            <Quote
              text={content.quote2.text.internal.content}
              user={content.quote2.user}
              userPhoto={content.quote2.userImage.fixed}
              userJob={content.quote2.jobDescription}
              color="vppBluePrimary"
              mobileComplete
            />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBg}>
          <Container className={styles.resultsContentWrapper}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                    <FormattedMessage id="casesCommon.results" />
                    <Text color="camioSecondary" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>
              </Col>
              {getMappable('results', 3, content).map((result, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={styles.resultsColumnWrapper}
                  key={result.title}
                >
                  <div className={styles.resultsImageWrapper}>
                    <Fade distance="20%" delay={200} bottom>
                      <Image src={result.image.file.url} alt={result.image.description} />
                    </Fade>
                  </div>
                  <div className={styles.resultsTextWrapper}>
                    <Fade distance="10%" bottom>
                      <Heading
                        type={index === 2 ? 'h5' : 'h4'}
                        bold
                        color="camioSecondary"
                        className={styles.resultsText}
                      >
                        {result.title}
                      </Heading>
                      <Text size="18" color="white" className={styles.resultsText}>
                        {result.description}
                      </Text>
                    </Fade>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases currentProject="Camio" caseStudies={content.proudCases} extendedCTA />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulCamioCaseStudy($locale: String) {
    contentfulCamioCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroImageDesktop {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageMobile {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      challengeImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      quote1 {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      featuresTitle1
      featuresDescription1 {
        internal {
          content
        }
      }
      featuresImage1 {
        description
        file {
          url
        }
      }
      featuresTitle2
      featuresDescription2 {
        internal {
          content
        }
      }
      featuresImage2 {
        description
        file {
          url
        }
      }
      featuresTitle3
      featuresDescription3 {
        internal {
          content
        }
      }
      featuresImage3 {
        description
        file {
          url
        }
      }
      smartTitle
      smartDescription {
        internal {
          content
        }
      }
      smartImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      quote2 {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      resultsImage1 {
        description
        file {
          url
        }
      }
      resultsTitle1
      resultsDescription1
      resultsImage2 {
        description
        file {
          url
        }
      }
      resultsTitle2
      resultsDescription2
      resultsImage3 {
        description
        file {
          url
        }
      }
      resultsTitle3
      resultsDescription3
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

CamioCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default CamioCaseStudy
